'use client'

import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import styles from './PdfViewer.module.css'

import clsx from 'clsx'
import * as React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Document, Page, pdfjs } from 'react-pdf'
import type {
  DocumentCallback,
  Options,
  PageCallback,
} from 'react-pdf/dist/cjs/shared/types'

import { Icon } from '@wartek-id/icon'
import { Text } from '@wartek-id/text'

import { ASSET_PREFIX, KEYBOARD_KEYCODE } from '@/configs/constants'
/**
 * The type of error that can occur while loading the PDF.
 */
export type ErrorType =
  | 'InvalidPDFException'
  | 'MissingPDFException'
  | 'UnknownErrorException'
  | 'DefaultError'

/**
 * Props for the PdfViewer component.
 */
export interface PdfViewerProps extends React.HTMLProps<HTMLDivElement> {
  /**
   * The URL of the PDF file to display.
   */
  url: string
  /**
   * The width of the PDF viewer. Can be a CSS value (e.g., "500px").
   */
  width: string
  /**
   * The height of the PDF viewer. Can be a CSS value (e.g., "500px").
   */
  height: string
  /**
   * Whether to enable debug mode for the PDF viewer.
   */
  debug?: boolean
  /**
   * Additional CSS classes to apply to the page elements.
   */
  className?: string
  /**
   * Additional CSS classes to apply to the page elements.
   */
  pageClassname?: string
  /**
   * Custom options to configure the PDF viewer.
   */
  options?: Options
  /**
   * Custom error messages for different error types.
   */
  errorMessages?: Partial<Record<ErrorType, string>>
  /**
   * Hook to listen for when the PDF document is failed to load.
   */
  onDocumentLoadError?: (errorType: ErrorType) => void
  /**
   * Hook to listen for when the PDF document is successfully loaded.
   */
  onDocumentLoadSuccess?: () => void
  /**
   * Hook to listen for when page being rendered successfully.
   *
   * You can get the actual height after the page being rendered
   */
  onPageRenderSuccess?: (page: PageCallback) => void
  /**
   * Custom loader component to display while the PDF is loading.
   */
  Loader?: React.ReactNode
  /**
   * Custom loader render function to display when errors happened.
   */
  CustomError?: ({
    errorType,
  }: {
    errorType: ErrorType | null
  }) => React.ReactNode
  /**
   * Whether to show single page or all pages
   */
  singlePage?: boolean
  animation?: boolean
}

export const defaultErrorMessages: Record<ErrorType, string> = {
  DefaultError: 'Terjadi kesalahan saat memuat PDF.',
  InvalidPDFException: 'Berkas PDF tidak valid atau rusak.',
  MissingPDFException:
    'Berkas PDF tidak ditemukan, silahkan cek kembali alamat PDF yang ingin ditampilkan.',
  UnknownErrorException:
    'Terjadi kesalahan sistem saat hendak memuat berkas PDF.',
}

// pdfjs.version should 3.11.174
// if you need to update the version, please update the vendors in public directory
if (pdfjs.version === '3.11.174') {
  pdfjs.GlobalWorkerOptions.workerSrc = `${ASSET_PREFIX}/vendors/pdf.js/${pdfjs.version}/pdf.worker.min.js`
} else {
  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`
}

export const DefaultLoader = () => (
  <div className={clsx('pdfLoader', styles.pdfLoader)} data-testid="pdfLoader">
    <div role="status">
      <svg
        aria-hidden="true"
        className="h-8 w-8 animate-spin fill-blue-600 text-gray-200"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
      <span className="sr-only">Loading...</span>
    </div>
  </div>
)

const DEFAULT_ERROR_FN = ({
  errorType,
  messages,
}: {
  errorType: ErrorType | null
  messages: Record<ErrorType, string>
}) => (
  <div
    className={clsx('pdfError', styles.pdfError)}
    data-testid="error-message"
  >
    <img
      className="h-36 w-auto"
      src={`${ASSET_PREFIX}/images/img-hand-with-wrench.svg`}
      alt="Tangan memegang kunci inggris"
      loading="lazy"
      data-testid="img-empty-state"
    />
    <div className="space-y-2 text-center">
      <Text variant="heading-lg">Gagal memuat berkas PDF</Text>
      <Text color="subdued">{errorType && messages?.[errorType]}</Text>
    </div>
  </div>
)

const DEFAULT_OPTIONS = {
  isEvalSupported: false,
}

export const PdfViewer = ({
  url,
  singlePage = false,
  width: propsWidth,
  height: propsHeight,
  pageClassname: pageClasses = '',
  errorMessages: customErrorMessages = {},
  onDocumentLoadSuccess = () => {},
  onDocumentLoadError = () => {},
  onPageRenderSuccess = () => {},
  Loader,
  debug = false,
  CustomError,
  className = '',
  animation = false,
}: PdfViewerProps) => {
  const errorMessages: Record<ErrorType, string> = {
    ...defaultErrorMessages,
    ...customErrorMessages,
  }
  const [numPages, setNumPages] = React.useState<number>(1)
  const [pageNumber, setPageNumber] = React.useState(1)
  const [renderedPageNumber, setRenderedPageNumber] = React.useState(1)
  const [error, setError] = React.useState<ErrorType | null>(null)
  const container = React.useRef<HTMLDivElement | null>(null)
  const [trigerredBy, setTriggeredBy] = React.useState<string>('default')

  const nextButtonRef = React.useRef<HTMLButtonElement | null>(null)
  const prevButtonRef = React.useRef<HTMLButtonElement | null>(null)

  const handleLoadSuccess = async (doc: DocumentCallback) => {
    setNumPages(doc.numPages)
    setPageNumber(1)
    onDocumentLoadSuccess?.()
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset)
  }

  function handlePrevPage() {
    setTriggeredBy('prev')
    changePage(-1)
  }

  function handleNextPage() {
    setTriggeredBy('next')
    changePage(1)
  }

  const isLoadingNewPage = renderedPageNumber !== pageNumber

  const handleKeyDown = (e) => {
    if (e.keyCode === KEYBOARD_KEYCODE.ARROW_LEFT) {
      //  Prev
      prevButtonRef.current?.focus()
      handlePrevPage()
    } else if (e.keyCode === KEYBOARD_KEYCODE.ARROW_RIGHT) {
      // Next
      nextButtonRef.current?.focus()
      handleNextPage()
    }
  }

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div
        className={clsx(className, debug && 'debug')}
        ref={container}
        style={{
          height: parseInt(propsHeight, 10),
          width: parseInt(propsWidth, 10),
          position: 'relative',
        }}
      >
        <div
          className={clsx('pdfContainer', styles.pdfContainer)}
          data-testid="pdfContainer"
          id="viewerContainer"
        >
          <div className={clsx('pdfViewer', styles.pdfViewer)}>
            <ErrorBoundary
              FallbackComponent={() => (
                <div className="fallback-err-boundary">
                  {CustomError
                    ? CustomError({
                        errorType: 'DefaultError',
                      })
                    : DEFAULT_ERROR_FN({
                        errorType: 'DefaultError',
                        messages: {
                          DefaultError:
                            'Terjadi kesalahan pada sistem saat berusaha memuat PDF.',
                        } as Record<ErrorType, string>,
                      })}
                </div>
              )}
            >
              <Document
                file={url}
                onLoadSuccess={handleLoadSuccess}
                onLoadError={(error) => {
                  onDocumentLoadError?.(error.name as ErrorType)
                  setError(error.name as ErrorType)
                }}
                options={DEFAULT_OPTIONS}
                externalLinkTarget="_blank"
                externalLinkRel="noopener noreferrer"
                data-testid="pdfDocument"
                loading={() => (Loader ? Loader : <DefaultLoader />)}
                error={() => (
                  <>
                    {CustomError
                      ? CustomError({
                          errorType: error,
                        })
                      : DEFAULT_ERROR_FN({
                          errorType: error,
                          messages: errorMessages,
                        })}
                  </>
                )}
                onItemClick={(eventProp) => {
                  if (singlePage) {
                    if (eventProp.pageNumber < pageNumber) {
                      setTriggeredBy('prev')
                    } else {
                      setTriggeredBy('next')
                    }
                    setPageNumber(eventProp.pageNumber)
                  }
                }}
              >
                {singlePage ? (
                  <>
                    {/** Delay the page trasition --> https://codesandbox.io/p/sandbox/react-pdf-prevent-flash-with-scale-nse51l **/}
                    {animation && isLoadingNewPage && renderedPageNumber ? (
                      <Page
                        loading={() => Loader}
                        key={`page_${renderedPageNumber + 1}`}
                        width={parseInt(propsWidth, 10)}
                        className={clsx(
                          pageClasses,
                          `page-${renderedPageNumber} is-prev-page`,
                          animation && 'transform transition-all duration-500',
                          animation &&
                            trigerredBy === 'next' &&
                            '-translate-x-full',
                          animation &&
                            trigerredBy === 'prev' &&
                            'translate-x-full'
                        )}
                        height={parseInt(propsHeight, 10)}
                        pageNumber={renderedPageNumber}
                      />
                    ) : null}
                    <Page
                      loading={() => Loader}
                      key={`page_${pageNumber + 1}`}
                      width={parseInt(propsWidth, 10)}
                      className={clsx(
                        pageClasses,
                        animation &&
                          'transform transition-opacity duration-300 ease-in-out',
                        `page-${pageNumber} is-current-page`,
                        isLoadingNewPage ? 'opacity-0' : 'opacity-100'
                      )}
                      height={parseInt(propsHeight, 10)}
                      pageNumber={pageNumber}
                      onRenderSuccess={(cb) => {
                        setTimeout(
                          () => {
                            setRenderedPageNumber(pageNumber)
                            onPageRenderSuccess?.(cb)
                          },
                          animation ? 300 : 0
                        )
                      }}
                    />
                  </>
                ) : (
                  <>
                    {Array.from(new Array(numPages), (el, index) => {
                      return (
                        <Page
                          loading={() => Loader}
                          key={`page_${index + 1}`}
                          width={parseInt(propsWidth, 10)}
                          className={clsx(pageClasses, `page-${index + 1}`)}
                          height={parseInt(propsHeight, 10)}
                          pageNumber={index + 1}
                          onRenderSuccess={onPageRenderSuccess}
                        />
                      )
                    })}
                  </>
                )}
              </Document>
            </ErrorBoundary>
          </div>

          {singlePage && !error ? (
            <section
              className="absolute bottom-0 left-0 flex h-[65px] w-full items-center justify-between rounded-b border-t border-t-disabled bg-gray-5 px-1 py-2"
              id="pdf-pagination"
            >
              <button
                data-testid="btn-pdf-prev"
                type="button"
                disabled={pageNumber <= 1}
                onClick={handlePrevPage}
                className={clsx(
                  'flex h-[calc(65px-0.5rem)] cursor-pointer items-center justify-center gap-1 rounded-bl bg-gray-5 px-5 hover:bg-gray-10 focus:bg-gray-10 focus:ring-2 focus:ring-blue-70 focus:ring-offset-2'
                )}
                ref={prevButtonRef}
              >
                <Icon
                  color={pageNumber <= 1 ? 'disabled' : 'default'}
                  className="mr-1"
                >
                  arrow_back
                </Icon>
                <Text
                  variant="body-sm"
                  color={pageNumber <= 1 ? 'disabled' : 'default'}
                  className="hidden md:block"
                >
                  Halaman Sebelumnya
                </Text>
              </button>
              <div className="rounded-full bg-white px-4 py-1">
                <Text variant="body-sm">
                  Halaman{' '}
                  <Text variant="body-sm-bold" as="span">
                    <span data-testid="txt-pdf-page-current">
                      {pageNumber || (numPages ? 1 : '--')}
                    </span>
                    {` `}dari{' '}
                    <span data-testid="txt-pdf-page-total">
                      {numPages || '--'}
                    </span>
                  </Text>
                </Text>
              </div>
              <button
                type="button"
                data-testid="btn-pdf-next"
                disabled={pageNumber >= numPages}
                onClick={handleNextPage}
                className={clsx(
                  'flex h-[calc(65px-0.5rem)] cursor-pointer items-center justify-center gap-1 rounded-br bg-gray-5 px-5 hover:bg-gray-10 focus:bg-gray-10 focus:ring-2 focus:ring-blue-70 focus:ring-offset-2'
                )}
                ref={nextButtonRef}
              >
                <Text
                  variant="body-sm"
                  color={pageNumber >= numPages ? 'disabled' : 'default'}
                  className="hidden md:block"
                >
                  Halaman Selanjutnya
                </Text>
                <Icon
                  color={pageNumber >= numPages ? 'disabled' : 'default'}
                  className="ml-1"
                >
                  arrow_forward
                </Icon>
              </button>
            </section>
          ) : null}
        </div>
      </div>
    </>
  )
}
