class Storage<ValueType> {
  _storageKey: string = ''
  _defaultVal: ValueType | null = null
  _storageProvider: 'local' | 'session' = 'local'

  constructor(
    storageKey: string,
    defaultVal?: ValueType,
    storageProvider?: 'local' | 'session'
  ) {
    this._storageKey = storageKey

    if (typeof defaultVal !== 'undefined') {
      this._defaultVal = defaultVal
    }

    if (typeof storageProvider !== 'undefined') {
      this._storageProvider = storageProvider
    }
  }

  getKey(): string {
    return this._storageKey
  }

  getDefaultValue(): ValueType | null {
    return this._defaultVal
  }

  getStorageProvider() {
    if (this._storageProvider === 'session') {
      return window.sessionStorage
    }

    return window.localStorage
  }

  getValue(): ValueType | null {
    try {
      if (typeof window !== 'undefined') {
        const item = this.getStorageProvider().getItem(this._storageKey)
        return item ? (JSON.parse(item) as ValueType) : this.getDefaultValue()
      }
      return this.getDefaultValue()
    } catch (error) {
      console.error(
        `Error while getValue in class Storage, key: ${this._storageKey}`,
        error
      )
      return this._defaultVal
    }
  }

  setValue(newValue: ValueType): void {
    try {
      if (typeof window !== 'undefined') {
        this.getStorageProvider().setItem(
          this._storageKey,
          JSON.stringify(newValue)
        )
      }
    } catch (error) {
      console.error(
        `Error while setValue in class Storage, key: ${this._storageKey}`,
        error
      )
    }
  }

  removeValue(): void {
    try {
      if (typeof window !== 'undefined') {
        this.getStorageProvider().removeItem(this._storageKey)
      }
    } catch (error) {
      console.error(
        `Error while removeValue in class Storage, key: ${this._storageKey}`,
        error
      )
    }
  }
}

export default Storage
