'use client'

import styles from './pdf.module.css'

import clsx from 'clsx'
import dynamic from 'next/dynamic'
import { useEffect, useState } from 'react'
import { type PageCallback } from 'react-pdf/dist/cjs/shared/types'

import { Container } from '@/components/Container'
import { DefaultLoader, ErrorType } from '@/components/PdfViewer/PdfViewer'
// import { DefaultLoader, ErrorType } from '@/components/PdfViewer/PdfViewer'

const PdfViewer = dynamic(() =>
  import('@/components/PdfViewer/PdfViewer').then((mod) => mod.PdfViewer)
)

const SPACE_FOR_PAGINATION = 70

export const DynamicWrapper = ({
  url,
  initialHeight = 400,
  initialWidth = 300,
  errorMessages,
  onFinish,
  onError,
  singlePage = false,
  Loader,
  CustomError,
  animation = false,
}: {
  url: string
  initialHeight?: number
  initialWidth?: number
  onFinish?: (cb?: PageCallback) => void
  onError?: () => void
  /**
   * Custom error messages for different error types.
   */
  errorMessages?: Partial<Record<ErrorType, string>>
  /**
   * Custom loader component to display while the PDF is loading.
   */
  Loader?: React.ReactNode
  /**
   * Custom loader render function to display when errors happened.
   */
  CustomError?: ({
    errorType,
  }: {
    errorType: ErrorType | null
  }) => React.ReactNode
  /**
   * Whether to show single page or all pages
   */
  singlePage?: boolean
  animation?: boolean
}) => {
  const [isLoading, setLoading] = useState(true)
  const [wrapperWidth, setWrapperWidth] = useState(initialWidth)
  const [dynamicHeight, setDinamicHeight] = useState(initialHeight)

  useEffect(() => {
    if (!isLoading) {
      if (typeof window !== 'undefined') {
        const el = document.getElementById('pdf-preview')
        if (el) {
          const offsetWidth = el.offsetWidth
          if (offsetWidth >= 0) {
            setWrapperWidth(Math.round(offsetWidth))
          }
        }
      }
    }
  }, [isLoading])

  return (
    <div className="dynamic-wrapper relative bg-transparent">
      <i
        style={{ display: 'none', position: 'absolute' }}
        data-pdf-url={url}
        data-testid="txt-pdf-url"
      ></i>
      <Container removePadding={true}>
        <div
          className={clsx(
            'relative mx-auto w-full rounded-lg border bg-white',
            styles.pdf
          )}
          id="pdf-preview"
          style={{
            minHeight: `${initialHeight}px`,
            height: `${singlePage ? dynamicHeight + SPACE_FOR_PAGINATION : dynamicHeight}px`,
          }}
        >
          {isLoading && Loader && <>{Loader}</>}
          {isLoading && !Loader && <DefaultLoader />}

          <PdfViewer
            url={url}
            errorMessages={errorMessages}
            className={isLoading ? 'hidden' : ''}
            Loader={Loader}
            CustomError={CustomError}
            debug={false}
            width={`${wrapperWidth - 3}px`}
            height={`${dynamicHeight}px`}
            pageClassname="rounded-lg overflow-hidden"
            data-testid="pdf-viewer-el"
            singlePage={singlePage}
            animation={animation}
            onPageRenderSuccess={(cb) => {
              if (cb.height) {
                setDinamicHeight(Math.round(cb.height))
              }

              setTimeout(() => {
                setLoading(false)
                onFinish?.(cb)
              }, 2000)
            }}
            onDocumentLoadError={() => {
              onError?.()

              setTimeout(() => {
                setLoading(false)
                onFinish?.()
              }, 500)
            }}
          />
        </div>
      </Container>
    </div>
  )
}
